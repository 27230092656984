import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import debounce from "lodash.debounce";
import {
  createSocialEmission,
  dowloadSocialEmission,
  getSuggestions,
} from "../api/socialEmission.api";


function ScopeEmission({ sideBarFlag, setSideBarFlag }) {
  const [companyName, setCompanyName] = useState("");
  const [scope1, setScope1] = useState("");
  const [scope2, setScope2] = useState("");
  const [scope3, setScope3] = useState("");
  const [email, setEmail] = useState("");
  const [keyword, setKeyword] = useState("");
  const [lastKeyword, setLastKeyword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1999 },
    (_, index) => 2000 + index,
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [createdYear, setCreatedYear] = useState(currentYear);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = () => {
    if (
      !companyName || (
      !scope1 &&
      !scope2 &&
      !scope3 ) || 
      !email ||
      !createdYear
    ) {
      alert("Please Fill up all Fields!");
      return;
    }

    const emailDomain = email.split("@")[1].split(".")[0];
    const normalizedCompanyName = companyName.replace(/\s+/g, "").toLowerCase();
    if (emailDomain.toLowerCase() !== normalizedCompanyName.toLowerCase()) {
      alert(
        "Thank you for assisting us in collecting our emissions, but your domain doesn’t match, we will contact you shortly!",
      );
      return;
    }

    createSocialEmission({
      email: email,
      scope1: scope1,
      scope2: scope2,
      scope3: scope3,
      createdYear: createdYear,
      companyName: companyName,
    })
      .then((res) => {
        setCompanyName("");
        setEmail("");
        setScope1("");
        setScope2("");
        setScope3("");
        setCreatedYear(currentYear);
        alert("Emission Data Successfully Uploaded!");
      })
      .catch((err) => {
        console.log(err);
        alert("We’ll Contact you on your email!");
      });
  };

  const fetchSuggestions = async (keyword) => {
    if (keyword.length < 2) {
      setSuggestions([]);
      return;
    }

    if (lastKeyword === keyword) return;

    try {
      const response = await getSuggestions(keyword);
      if (response.status === 200 && response.data.success) {
        setSuggestions(response.data.companyNames);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickCompany = (value) => {
    setSelectedCompany(value);
    setKeyword(value);
  };

  const onClickClearCompany = () => {
    setSelectedCompany("");
    setSuggestions([]);
    setKeyword("");
    setLastKeyword("````");
  };

  const onClickDownloadCompany = async () => {
    if (!suggestions.includes(keyword)) {
      setLastKeyword(keyword);
      alert("We currently don't have data for this year. It may or may not exist in the public form but we will endeavour to find the data.")
      return;
    }

    if (!selectedCompany) return;

    try {
      const response = await dowloadSocialEmission(
        selectedCompany,
        selectedYear,
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedCompany}_${selectedYear}.csv`); // File name
      document.body.appendChild(link);
      link.click();
      link.remove();
      alert("Emission Data Successfully Downloaded!")
    } catch (error) {
      if (error.response.status === 404) {
        alert(
          "Please note these emissions aren’t available at the moment, we’ll get back to you in 2 business days",
        );
        return;
      }
      alert("We’ll Contact you on your email!");
    }
  };

  const debouncedFetchSuggestions = debounce(fetchSuggestions, 500);

  useEffect(() => {
    if (selectedCompany) {
      return;
    }
    debouncedFetchSuggestions(keyword);

    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, [keyword]);

  return (
    <div className="ContactUsPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="main">
        <div className="card-container card-scope">
          <div className="card">
            <div className="main">
              <div className="left">
                <span className="title">Search Carbon Emissions</span>
                {/* Add your components or logic for searching and downloading emission data */}
                <div className="search-box">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Search Company"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  {suggestions.length === 0 && <div style={{ marginTop: '5px', color: 'gray' }}>* Use Full Names of Companies</div>}
                  {!selectedCompany &&
                    suggestions &&
                    suggestions.length > 0 && (
                      <ul className="suggestions">
                        {suggestions.map((suggestion) => (
                          <li onClick={() => onClickCompany(suggestion)}>
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  <select
                    className="input-box"
                    id="year"
                    name="year"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <button onClick={() => onClickClearCompany()}>Clear</button>
                </div>
                <div className="download-links">
                  <button
                    disabled={selectedCompany === "" && keyword === ""}
                    onClick={() => onClickDownloadCompany()}
                  >
                    Download
                  </button>
                </div>

              </div>
              <hr />
              <div className="right">
              <span className="title" style={{ textAlign: 'center' }}>Upload Carbon Emissions</span>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="number"
                    placeholder="Scope 1 Emissions in (tCO2-e)"
                    value={scope1}
                    onChange={(e) => setScope1(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="number"
                    placeholder="Scope 2 Emissions in (tCO2-e)"
                    value={scope2}
                    onChange={(e) => setScope2(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="number"
                    placeholder="Scope 3 Emissions in (tCO2-e)"
                    value={scope3}
                    onChange={(e) => setScope3(e.target.value)}
                  />
                </div>
                <select
                  className="input-box"
                  id="currentyear"
                  name="currentyear"
                  value={createdYear}
                  onChange={(event) => setCreatedYear(event.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <div className="button" onClick={handleSubmit}>
                  Upload
                </div>
              </div>
            </div>
          </div>
          <div className="card card-scope">
            <span>
            This collects scope 1, 2, and 3 carbon emissions for entities globally. It is a complimentary tool for sustainability reporting, facilitating the global energy transition.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ScopeEmission;
