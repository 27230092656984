import { getAuthorizationToken } from "../utils/constants";
import axios from "axios";

export const createSocialEmission = (data) => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}/socialEmission`, data, {
    headers: {
      Authorization: getAuthorizationToken(),
    },
  });
};

export const getSuggestions = (keyword) => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/socialEmission/getUserbyCompanyKeywords?companyName=${keyword.toLowerCase()}`,
    {
      headers: {
        Authorization: getAuthorizationToken(),
      },
    },
  );
};

export const dowloadSocialEmission = (selectedCompany, selectedYear) => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/socialEmission/user/${selectedCompany.toLowerCase()}/${selectedYear}`,
    {
      responseType: "blob",
      headers: {
        Authorization: getAuthorizationToken(),
      },
    },
  );
};
