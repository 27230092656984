import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScopeEmission from "./pages/ScopeEmission";

function Routing() {
  const [sideBarFlag, setSideBarFlag] = useState(false);
  console.log(process.env.REACT_APP_SERVER_URL)

  return (
    <Router basename={process.env.REACT_APP_PUBLIC_URL}>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ScopeEmission
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default Routing;
