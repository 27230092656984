import React from "react";
// import headerLine from '../assets/images/header-line.png'
import headerLine from "../assets/images/Group 16.png";
import headerLogoLineMobile from "../assets/images/header-logo-line-mobile.png";
import { ReactComponent as MenuIcon } from "../assets/svg/menu.svg";
import Sidebar from "./Sidebar";

function Header({ sideBarFlag, setSideBarFlag }) {
  // const [tab, setTab] = useState(0)
  return (
    <div className="Header">
      <div className="white-area">
      <span>VeriCO2 is the owner of this database and it simplifies the reporting of carbon emissions. Please see <a href="https://verico2.com" target="_blank" rel="noreferrer" >verico2.com</a></span>

        <div
          className="menu-button"
          onClick={(e) => {
            e.stopPropagation();
            setSideBarFlag(!sideBarFlag);
          }}
        >
          <MenuIcon />
        </div>
      </div>
      <div className="header-logo-line">
        <img src={headerLine} alt="Header Line" className="header-line" />
        <div className="buttons">
              <span
                className={"selected"}
              >
                Carbon Emissions Registry (CER)
              </span>
        </div>
      </div>
      <div className="mobile-header">
        <img
          src={headerLogoLineMobile}
          alt="Header Logo Line Mobile"
          className="header-logo-line-mobile"
        />
      </div>
      <Sidebar sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
    </div>
  );
}

export default Header;
