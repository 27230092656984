import React from "react";

function Sidebar({ sideBarFlag, setSideBarFlag }) {
  return (
    <div
      className={sideBarFlag ? "Sidebar show" : "Sidebar"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="item"
        onClick={() => {}}
      >
        Emissions Register
      </div>
    </div>
  );
}

export default Sidebar;
